class ThOverlay {
    constructor() {
        this.overlaySelector = '.overlay';
        this.backdropSelector = '.shadow-overlay';

        //
        this.backdropElement = document.querySelector(this.backdropSelector);

        // RGAA
        this.currentOverlayId = null;
        this.openerElement = null;

        // Pour ajouter des fonctions de callback à l'ouverture / fermeture
        this.callbackOpen = [];
        this.callbackClose = [];

        this.init();
    }

    init() {
        this.backdropClickHandler();
        this.keyboardHandler();
        this.parse();
        this.openOnLoad();
    }

    // Fermeture de l'overlay au click sur l'arrière-plan
    backdropClickHandler() {
        this.backdropElement.addEventListener('click', e => {
            this.close();
        });
    }


    // RGAA : fermeture de l'overlay courant avec la touche ESC
    keyboardHandler() {
        document.addEventListener('keydown', (e) => {
            if (this.currentOverlayId && e.key === "Escape") {
                this.close();
            }
        });
    }

    // Ajoute une fonction de callback à l'ouverture de l'overlay
    addCallbackOpen(callback) {
        this.callbackOpen.push(callback);
    }


    // Ajoute une fonction de callback à la fermeture de l'overlay
    addCallbackClose(callback) {
        this.callbackClose.push(callback);
    }


    parse() {
        document.querySelectorAll(this.overlaySelector).forEach((overlay) => {
            const overlayId = overlay.id;

            // boutons avec attribut data-overlay-open ou liens en href=#overlay
            const triggersOpen = document.querySelectorAll(
                `[data-overlay-open="${overlayId}"]:not(.parsed-overlay), a[href="#${overlayId}"]:not(.parsed-overlay)`);
            triggersOpen.forEach((trigger) => {
                trigger.classList.add('parsed-overlay');

                // RGAA : comportement de bouton pour les liens
                if (trigger.tagName === 'A') trigger.setAttribute('type', 'button');

                trigger.addEventListener('click', e => {
                    // empêche le click si aria-disabled
                    if (trigger.getAttribute('aria-disabled') === 'true') return;

                    // RGAA : On stocke l'element qui a ouvert l'overlay pour y revenir a sa fermeture
                    this.openerElement = trigger;

                    this.open(overlayId);
                });
            });

            // boutons de fermeture
            const triggersClose = document.querySelectorAll(`[data-overlay-close="${overlayId}"]:not(.parsed-overlay)`);
            triggersClose.forEach((trigger) => {
                trigger.classList.add('parsed-overlay');

                trigger.addEventListener('click', e => {
                    this.close(overlayId);
                });
            });
        });
    }


    // Ouverture automatique si le hash dans l'url correspond à l'id d'un overlay
    openOnLoad() {
        if (window.location.hash) {
            const overlayId = window.location.hash.substring(1);
            const overlayEl = document.getElementById(overlayId);

            if (overlayEl && overlayEl.classList.contains('overlay')) {
                this.open(overlayId);
            }
        }
    }

    // Ouvre un overlay à partir de son id
    open(overlayId, openBackdrop = true, doCallback = true) {

        // Referme les overlays déja ouverts sans fermer le backdrop
        document.querySelectorAll(`${this.overlaySelector}.is-open`).forEach((overlay) => {
            this.close(overlay.id, false, true);
        });

        const overlayEl = document.getElementById(overlayId);
        if (overlayEl && overlayEl.classList.contains('overlay')) {
            overlayEl.classList.add('is-open');

            if (openBackdrop) {
                this.backdropElement.classList.add('is-open');
            }

            // par défaut set un hash dans l'url pour accés direct de l'overlay
            // hash désactivée si data-disable-hash existe
            const disableHash = overlayEl.dataset.overlayDisableHash;
            if (disableHash !== '' && disableHash !== 'true') {
                window.location.hash = overlayId;
            }

            if (doCallback) {
                this.callbackOpen.forEach((fnCallback) => {
                    fnCallback(overlayId);
                });
            }

            this.currentOverlayId = overlayId;

            // RGAA : Focus de la croix de fermeture a l'ouverture de l'overlay
            this.setFocusOnOpen(overlayId, overlayEl);
        }
    }

    close(overlayId = null, closeBackdrop = true, doCallback = true) {
        window.location.hash = " ";
        history.replaceState(null, null, ' ');

        if (overlayId) {
            const overlayEl = document.getElementById(overlayId);
            if (overlayEl && overlayEl.classList.contains('overlay')) {
                overlayEl.classList.remove('is-open');
            }
        } else {
            document.querySelectorAll(`${this.overlaySelector}.is-open`).forEach((overlay) => {
                overlay.classList.remove('is-open');
            });
        }

        if (closeBackdrop) {
            this.backdropElement.classList.remove('is-open');
        }

        if (doCallback) {
            this.callbackClose.forEach((fnCallback) => {
                fnCallback(overlayId);
            });
        }

        this.currentOverlayId = null;

        // RGAA : focus sur l'élément qui a ouvert l'overlay
        this.setFocusOnClose();
    }


    // RGAA : quand fermeture d'un overlay, focus a nouveau sur l'élément qui l'a ouvert
    setFocusOnClose() {
        if (this.openerElement) {
            setTimeout(() => {
                this.openerElement.focus();
                this.openerElement = null;
            }, 1250);
        }
    }


    // RGAA : focus a nouveau sur l'élément qui a ouvert l'overlay
    setFocusOnOpen(overlayId, overlayNode) {
        setTimeout(() => {
            // Focus sur le champ de recherche dans l'overlay de recherche
            if (overlayId === 'overlay-search') {
                const inputSearch = overlayNode.querySelector('.js-search-input');
                if (inputSearch) inputSearch.focus();
            } else {
                const btnClose = overlayNode.querySelector('button[data-overlay-close]');
                if (btnClose) btnClose.focus();
            }
        }, 250);
    }
}